@import "../../assets/styles/utils";

.pages,#page ul{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  @include res(font-size, .18rem,(md:14px));
  @include res(margin-top,.6rem);
  a,li{
    display: inline-block;
    cursor: pointer;
    @include res(padding-left,.25rem);
    @include res(padding-right,.25rem);
    @include res(padding-top,.15rem);
    @include res(padding-bottom,.15rem);
    &:hover{
      color: $color-main;
    }
    &.active,&.xl-active{
      color: $color-main;
    }
    &.prev,&.next,&.xl-prevPage,&.xl-nextPage{
      position: absolute;
      top: 0;
      box-shadow: 0 .05rem .2rem rgba(0,0,0,.1);
      @include res(display,block);
      @include res(padding-left,.3rem);
      @include res(padding-right,.3rem);
      &.disabled{
        opacity: 0;
        visibility: hidden;
      }
    }
    &.prev,&.xl-prevPage{
      @include res(left, .9rem,(md:1rem,sm:1.1rem));
      &.first{
        left: 0;
      }
    }
    &.next,&.xl-nextPage{
      @include res(right, .9rem,(md:1rem,sm:1.1rem));
      &.end{
        right: 0;
      }
    }
  }
}