.pages, #page ul {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  font-size: 0.18rem;
  margin-top: 0.6rem;
}

@media (max-width: 1024px) {
  .pages, #page ul {
    font-size: 14px;
  }
}

.pages a, .pages li, #page ul a, #page ul li {
  display: inline-block;
  cursor: pointer;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
}

.pages a:hover, .pages li:hover, #page ul a:hover, #page ul li:hover {
  color: #384dc3;
}

.pages a.active, .pages a.xl-active, .pages li.active, .pages li.xl-active, #page ul a.active, #page ul a.xl-active, #page ul li.active, #page ul li.xl-active {
  color: #384dc3;
}

.pages a.prev, .pages a.next, .pages a.xl-prevPage, .pages a.xl-nextPage, .pages li.prev, .pages li.next, .pages li.xl-prevPage, .pages li.xl-nextPage, #page ul a.prev, #page ul a.next, #page ul a.xl-prevPage, #page ul a.xl-nextPage, #page ul li.prev, #page ul li.next, #page ul li.xl-prevPage, #page ul li.xl-nextPage {
  position: absolute;
  top: 0;
  box-shadow: 0 0.05rem 0.2rem rgba(0, 0, 0, 0.1);
  display: block;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
}

.pages a.prev.disabled, .pages a.next.disabled, .pages a.xl-prevPage.disabled, .pages a.xl-nextPage.disabled, .pages li.prev.disabled, .pages li.next.disabled, .pages li.xl-prevPage.disabled, .pages li.xl-nextPage.disabled, #page ul a.prev.disabled, #page ul a.next.disabled, #page ul a.xl-prevPage.disabled, #page ul a.xl-nextPage.disabled, #page ul li.prev.disabled, #page ul li.next.disabled, #page ul li.xl-prevPage.disabled, #page ul li.xl-nextPage.disabled {
  opacity: 0;
  visibility: hidden;
}

.pages a.prev, .pages a.xl-prevPage, .pages li.prev, .pages li.xl-prevPage, #page ul a.prev, #page ul a.xl-prevPage, #page ul li.prev, #page ul li.xl-prevPage {
  left: 0.9rem;
}

@media (max-width: 1024px) {
  .pages a.prev, .pages a.xl-prevPage, .pages li.prev, .pages li.xl-prevPage, #page ul a.prev, #page ul a.xl-prevPage, #page ul li.prev, #page ul li.xl-prevPage {
    left: 1rem;
  }
}

@media (max-width: 991px) {
  .pages a.prev, .pages a.xl-prevPage, .pages li.prev, .pages li.xl-prevPage, #page ul a.prev, #page ul a.xl-prevPage, #page ul li.prev, #page ul li.xl-prevPage {
    left: 1.1rem;
  }
}

.pages a.prev.first, .pages a.xl-prevPage.first, .pages li.prev.first, .pages li.xl-prevPage.first, #page ul a.prev.first, #page ul a.xl-prevPage.first, #page ul li.prev.first, #page ul li.xl-prevPage.first {
  left: 0;
}

.pages a.next, .pages a.xl-nextPage, .pages li.next, .pages li.xl-nextPage, #page ul a.next, #page ul a.xl-nextPage, #page ul li.next, #page ul li.xl-nextPage {
  right: 0.9rem;
}

@media (max-width: 1024px) {
  .pages a.next, .pages a.xl-nextPage, .pages li.next, .pages li.xl-nextPage, #page ul a.next, #page ul a.xl-nextPage, #page ul li.next, #page ul li.xl-nextPage {
    right: 1rem;
  }
}

@media (max-width: 991px) {
  .pages a.next, .pages a.xl-nextPage, .pages li.next, .pages li.xl-nextPage, #page ul a.next, #page ul a.xl-nextPage, #page ul li.next, #page ul li.xl-nextPage {
    right: 1.1rem;
  }
}

.pages a.next.end, .pages a.xl-nextPage.end, .pages li.next.end, .pages li.xl-nextPage.end, #page ul a.next.end, #page ul a.xl-nextPage.end, #page ul li.next.end, #page ul li.xl-nextPage.end {
  right: 0;
}
