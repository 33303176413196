@import "./utils";
@import "~jason-css/src/responsive/index";
@import "~swiper/css/swiper.min.css";
@import '~@fancyapps/fancybox/dist/jquery.fancybox.min.css';
@import './animate.css';
@import "../fonts/iconfont.css";

@font-face {
  font-family: 'din';
  src: url('../fonts/DIN-Medium.eot');
  src: url('../fonts/DIN-Medium.woff') format('woff'),
    url('../fonts/DIN-Medium.ttf') format('truetype'),
    url('../fonts/DIN-Medium.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'dincond';
  src: url('../fonts/dincond-bold.eot');
  src: url('../fonts/dincond-bold.woff') format('woff'),
    url('../fonts/dincond-bold.ttf') format('truetype'),
    url('../fonts/dincond-bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline:none;
}
html{
  font-size: 62.5%;
}
body {
  font-family: "PingFang SC","Microsoft YaHei";
  line-height: 1.625;
  position: relative;
  color: #000;
  background-color: #fff;
  @include res(letter-spacing, .01rem);
  @include res(font-size, 12px);
}
.comp-root{
  overflow-x: hidden;
  @include res(font-size,.16rem,(md:16px));
}
.swiper-button-next, .swiper-button-prev{
  z-index: 3;
}
.common-hearder-tran{
  @include res(margin-top,.4rem,(md:0));
}
.pd-btm{
  @include res(padding-bottom,1.4rem);
}
//主体内容部分宽度
.container {
  @include res(padding-left, 12.5%, 5 / 12.5);
  @include res(padding-right, 12.5%, 5 / 12.5);
}

.red{
  color: $color-red;
}
.pd-btm{
  @include res(padding-bottom,1.5rem);
}

// 列表中图片有占位图片的
.haszw-img{
  position: relative;
  overflow: hidden;
  img{
    display: block;
    width: 100%;
    height: auto;
    opacity: 0;
  }
  b,a{
    display: block;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    position: absolute;
    top: -2px;
    left: -2px;
    background-position: center;
    background-size: cover;
    transition: all .4s;
  }
}

.common-title{
  @include res(text-align,null,(sm:center));
  @include res(margin-bottom, .8rem,(md:40px,xs:25px));
  @include res(padding-left,.35rem,(xs:0));
  &.t-center{
    text-align: center;
    padding-left: 0;
  }
  h2{
    font-weight: bold;
    white-space: nowrap;
    @include res(font-size,.42rem,(md:24px));
    span{
      position: relative;
      &::before{
        content: '';
        display: block;
        background: url(../../assets/images/icon_title.png) no-repeat center center;
        background-size: contain;
        position: absolute;
        transform: translateY(-50%);
        @include res(top,50%);
        @include res(right,calc(100% + .15rem));
        @include res(width, .2rem);
        @include res(height, .44rem);
      }
    }
  }
  h3{
    text-transform: uppercase;
    font-family: 'din';
    white-space: nowrap;
    @include res(font-size,.28rem);
  }
  .desc{
    @include res(font-size,.22rem,(md:16px));
    @include res(margin-top,.2rem);
  }
}

.common-link{
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  border: solid rgba(0,0,0,.2);
  text-transform: uppercase;
  font-family: 'din';
  transition: all .4s;
  font-weight: normal;
  @include res(border-width,0.01rem,(md:1px));
  @include res(padding-top,.25rem);
  @include res(padding-bottom,.25rem);
  @include res(padding-left,.6rem);
  @include res(padding-right,.6rem);
  &:hover{
    color: #fff;
    background-color: $color-main;
    border: solid $color-main;
    @include res(border-width,0.01rem,(md:1px));
    .iconfont{
      transform: translateX(.05rem);
    }
  }
  span{
    // display: none;
    @include res(font-size,.16rem,(md:14px));
    @include res(margin-right, .4rem);
  }
  .iconfont{
    display: inline-block;
    transition: transform .4s;
    @include res(font-size, .2rem);
  }
}

.common-link2{
  display: inline-block;
  color: #fff;
  transition: all .4s;
  border-color: $color-main;
  text-align: center;
  margin-bottom: .1rem;
  background-color: $color-main;
  box-sizing: border-box;
  border-style: solid;
  white-space: nowrap;
  @include res(border-width,.01rem,(md:1px));
  @include res(font-size,.2rem,(md:16px,xs:14px));
  @include res(padding-left,.4rem);
  @include res(padding-right,.4rem);
  @include res(padding-top,.1rem);
  @include res(padding-bottom,.1rem);
  &:not(:last-child){
    @include res(margin-right,.3rem);
  }
  &:hover{
    border-color: $color-red;
    background-color: $color-red;
    .iconfont{
      transform: translateX(.05rem);
    }
  }
  .iconfont{
    display: inline-block;
    transition: transform .4s;
    @include res(font-size, .2rem);
    @include res(margin-left, .1rem);
  }
  &.white{
    border: solid #fff;
    background-color: #fff;
    color: $color-main;
    &:hover{
      border-color: $color-main;
      background-color: $color-main;
      color: #fff;
      
    }
  }
  &.transparent{
    border-color: $color-main;
    background-color: transparent;
    color: $color-main;
    &:hover{
      border-color: $color-main;
      background-color: $color-main;
      color: #fff;
    }
  }
}

.ny-common-link{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: solid #999999;
  transition: all .3s;
  white-space: nowrap;
  font-weight: bold;
  @include res(font-size,.16rem,(md:14px));
  @include res(width,1.75rem,(md:150px,xs:130px));
  @include res(border-width,.01rem,(md:1px));
  @include res(padding-top,.15rem,(md:10px,xs:8px));
  @include res(padding-bottom,.15rem,(md:10px,xs:8px));
  @include res(padding-left,.15rem,(md:20px,xs:10px));
  @include res(padding-right,.15rem,(md:20px,xs:10px));
  .iconfont{
    font-weight: normal;
    @include res(font-size,.16rem,(md:14px));
  }
  &:not(:last-child){
    @include res(margin-right,.15rem);
    @include res(margin-bottom,null,(md:10px));
  }
  &:hover{
    background-color: $color-main;
    border-color: $color-main;
    color: #fff;
  }
}

//内页二级导航
.ny-nav{
  position: relative;
  z-index: 12;
  white-space: nowrap;
  @include res(display,block,(xs:none));
  @include res(margin-bottom,.4rem);
  .ny-nav-fixed{
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    top: auto;
    @media screen and(max-width:768px) {
      @include res(padding-left, 10%,(xs:0));
      @include res(padding-right, 10%,(xs:0));
    }
    &.fixed{
      position: fixed;
      z-index: 10;
      top: 0;
      left: 0;
      bottom: auto;
      @include res(transition,transform .4s,(md:none));
      .cont{
        .swiper .swiper-wrapper .swiper-slide a{
          @include res(padding-top,.15rem);
          @include res(padding-bottom,.15rem);
        }
        .swiper-btn{
          .swiper-button-prev,.swiper-button-next{
            @include res(width,.4rem,(md:30px));
            @include res(height,.4rem,(md:30px));
          }
        }
      }
    }
    .cont{
      position: relative;
      background-color: #fff;
      @include res(box-shadow,0 .1rem .2rem rgba(0,0,0,.1),(xs:none));
      .swiper{
        @include res(overflow,hidden,(xs:visible));
        .swiper-wrapper{
          display: inline-flex;
          .swiper-slide{
            flex-grow: 1;
            width: auto;
            position: relative;
            transition: all .4s;
            background-color: #fff;
            overflow: visible;
            @include res(box-shadow,none,(xs:0 .1rem .3rem -.1rem rgba(0,0,0,.1)));
            &:last-child{
              a::after{
                display: none;
              }
            }
            a{
              display: block;
              text-align: center;
              color: #a7a7a7;
              transition: all .4s;
              position: relative;
              z-index: 1;
              @include res(padding-top,.45rem,(md:15px));
              @include res(padding-bottom,.45rem,(md:15px));
              @include res(font-size,.2rem,(md:16px,sm:14px,xs:.26rem));
              &:hover{
                color: $color-main;
              }
              &::after{
                content: '';
                display: block;
                position: absolute;
                z-index: 1;
                @include res(top,calc(50% - .1rem),(xs:calc(50% - 8px)));
                @include res(height,.2rem,(xs:16px));
                @include res(width,.01rem,(md:1px));
                @include res(right,-.01rem,(md:0px));
                @include res(background-color,rgba(0,0,0,.2),(sm:rgba(0,0,0,.1)));
              }
            }
            img{
              position: absolute;
              bottom: 0;
              left: 10%;
              opacity: 0;
              height: 80%;
              width: auto;
              display: block;
              z-index: 0;
              transition: all .4s;
            }
            &.active{
              background-color: $color-main;
              a{
                color: #fff;
              }
              img{
                opacity: .2;
              }
            }
          }
        }
      }
      .swiper-btn{
        display: none;
        .swiper-button-prev,.swiper-button-next{
          background-color: #fff;
          background-image: none;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          transform: translateY(-50%);
          box-shadow: 0 .1rem .2rem rgba(0,0,0,.1);
          transition: all .3s;
          @include res(width,.6rem,(md:30px));
          @include res(height,.6rem,(md:30px));
          &:hover{
            background-color: $color-main;
            color: #fff;
          }
          &::after{
            font-family: 'iconfont';
            font-weight: bold;
            @include res(font-size, .14rem);
          }
        }
        .swiper-button-prev{
          left: auto;
          @include res(right,calc(100% + .5rem),(xs:calc(100% + 10px)));
          &::after{
            content: '\eaf3';
          }
        }
        .swiper-button-next{
          right: auto;
          @include res(left,calc(100% + .5rem),(xs:calc(100% + 10px)));
          &::after{
            content: '\e8d4';
          }
        }
      }
    }
  }
  
}

//公共表单
.common-form-cont{
  @include res(padding-left,1.1rem,(md:0));
  @include res(padding-right,1.1rem,(md:0));
  @include res(padding-top,1rem,(md:30px,xs:0));
  @include res(padding-bottom,1.6rem,(md:40px,xs:0));
  .form{
    @include res(box-shadow,0 0 .2rem rgba(0,0,0,.1),(xs:none));
    @include res(padding-left,2rem,(md:30px,xs:0));
    @include res(padding-right,2.5rem,(md:30px,xs:0));
    @include res(padding-top,.5rem,(md:30px,xs:0));
    @include res(padding-bottom,.8rem,(md:30px,xs:15px));
    .form-title{
      text-align: center;
      font-weight: bold;
      @include res(display,nll,(xs:none));
      @include res(margin-bottom,.45rem,(md:20px));
      @include res(font-size,.28rem,(md:18px));
    }
    .form-item,.form-btn,.form-radio{
      align-items: flex-start;
      position: relative;
      flex-wrap: wrap;
      @include res(display,flex);
      @include res(margin-bottom,.2rem,(md:10px,xs:0));
      @include res(padding-bottom,0,(xs:5px));
      @include res(padding-top,0,(xs:5px));
      &::after{
        content: '';
        position: absolute;
        top: 0;
        left: -10%;
        z-index: -1;
        background-color: #fff;
        height: 100%;
        width: 120%;
        @include res(display,none,(xs:block));
      }
      &::before{
        content: '';
        position: absolute;
        bottom: 0;
        left: -10%;
        z-index: 1;
        background-color: #efefef;
        height: 1px;
        width: 120%;
        @include res(display,none,(xs:block));
      }
      &.form-btn{
        &::before,&::after{
          display: none;
        }
        @include res(padding-left,1.75rem,(md:160px,xs:0));
        @include res(margin-top,.45rem,(md:20px));
      }
      &.form-radio{
        @include res(margin-bottom,.1rem,(md:5px,xs:0));
        >label{
          @include res(line-height,1.6,(xs:40px));
        }
      }
      >label{
        flex-shrink: 0;
        white-space: nowrap;
        flex-direction: row-reverse;
        justify-content: flex-end;
        @include res(display,null,(xs:inline-flex));
        @include res(line-height,.58rem,(md:40px));
        @include res(width,1.5rem,(md:150px,xs:110px));
        @include res(font-size, .18rem,(md:16px,xs:15px));
        @include res(text-align,right,(xs:left));
        @include res(margin-right,.25rem,(md:10px));
      }
      .item-input{
        display: flex;
        align-items: stretch;
        @include res(flex-grow,1,(xs:null));
        @include res(width,null,(xs:calc(100% - 120px)));
        @include res(background-color,#f5f5f5,(xs:transparent));
        @include res(padding-right,.2rem,(md:10px,xs:0));
        &.mob-full{
          @include res(width,null,(xs:100%));
          input{
            @include res(text-align,left,(xs:left));
          }
        }
        input{
          flex-grow: 1;
          min-width: 100px;
          border: none;
          background-color: transparent;
          @include res(text-align,left,(xs:right));
          @include res(font-size,.16rem,(md:16px,xs:14px));
          @include res(padding-left,.2rem,(md:10px));
          @include res(height,.58rem,(md:40px));
        }
        textarea{
          flex-grow: 1;
          min-width: 100px;
          border: none;
          background-color: transparent;
          @include res(height,null,(xs:35px));
          @include res(text-align,left);
          @include res(font-size,.16rem,(md:16px,xs:14px));
          @include res(padding-left,.2rem,(md:10px,xs:0));
          @include res(padding-top,.2rem,(md:10px,xs:0));
          @include res(padding-bottom,.2rem,(md:10px,xs:0));
        }
        .select{
          flex-shrink: 0;
          position: relative;
          @include res(display,null,(xs:none));
          @include res(width,100px,(md:100px));
          @include res(margin-right,.2rem,(md:10px));
          &::after{
            content: '';
            display: block;
            width: .01rem;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background-color: rgba(0,0,0,.2);
            @include res(right, -.15rem);
            @include res(height,.22rem);
          }
          &::before{
            content:"";
            position:absolute;
            top:50%;
            right:10px;
            margin-top:-2px;
            border-top:5px solid #666;
            border-right:5px solid transparent;
            border-left:5px solid transparent;
            pointer-events: none;
          }
          select{
            border: none;
            background-color: transparent;
            display: block;
            cursor: pointer;
            width: 100%;
            box-sizing: border-box;
            padding:0 20px 0 10px;
            @include res(height,.58rem,(md:40px));
          }
          input{
            width: 100%;
            box-sizing: border-box;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            cursor: pointer;
            @include res(font-size,null,(xs:14px));
            @include res(padding-left,.2rem,(md:10px));
            @include res(padding-right,.2rem,(md:10px));
            @include res(height,.58rem,(md:40px));
            @include res(line-height,.58rem,(md:40px)); 
          }
          ul{
            display:none;
            position:absolute;
            z-index: 2;
            left:0;
            min-width:134px;
            border:1px solid #D4D4D4;
            border-bottom-right-radius:3px;
            border-bottom-left-radius:3px;
            background:#fff;
            max-height: 300px;
            overflow-y: auto;
            @include res(top,.58rem,(md:40px));
            li{
              line-height:36px;
              text-indent:10px;
              transition: all .3s;
              cursor: pointer;
              &:hover{
                color:#fff;
                background:#6AA7EA;
              }
              &.cur{
                color:#fff;
                background:#195DA3;
              }
            }
          }
        }
        .btn{
          flex-shrink: 0;
          cursor: pointer;
          position: relative;
          transition: color .3s;
          border-radius: 5px;
          @include res(font-size,null,(xs:.28rem));
          @include res(color,$color-main,(xs:#fff));
          @include res(background-color,null,(xs:$color-main));
          @include res(padding-left,.15rem);
          @include res(padding-right,0,(xs:.15rem));
          @include res(margin-top,0,(xs:5px));
          @include res(margin-left,0,(xs:10px));
          @include res(height, .58rem,(md:40px,xs:30px));
          @include res(line-height, .58rem,(md:40px,xs:30px));
          span{
            @include res(display,null,(xs:none));
          }
          &:hover{
            @include res(color,$color-red,(xs:#fff));
          }
          &::after{
            content: '';
            width: .01rem;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background-color: $color-main;
            @include res(display,block,(xs:none));
            @include res(left, 0);
            @include res(height,.16rem,(md:16px));
          }
        }
        .yzm-img{
          display: inline-flex;
          align-items: center;
          justify-content: flex-end;
          @include res(height,.58rem,(md:40px));
          @include res(margin-right,-.2rem,(xs:0));
          @include res(margin-left,0,(xs:.2rem));
          @include res(width,1.5rem,(md:2.5rem,xs:3rem));
          img{
            display: block;
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
      .item-textarea{
        display: flex;
        align-items: stretch;
        @include res(flex-grow,1,(xs:null));
        @include res(width,null,(xs:calc(100% - 120px)));
        @include res(background-color,#f5f5f5,(xs:transparent));
        @include res(padding-right,.2rem,(md:10px,xs:0));
        .textarea-mask{
          width: 100%;
          height: 100%;
          background-color: rgba(0,0,0,.5);
          position: fixed;
          top:0;
          left: 0;
          z-index: 8;
          display: none;
        }
        .open-text{
          color: #666;
          line-height: 40px;
          text-align: right;
          width: 100%;
          @include res(font-size,null,(xs:14px));
          @include res(display,none,(xs:block));
          .iconfont{
            margin-left: 5px;
          }
        }
        .textarea{
          width: 100%;
          overflow: hidden;
          transition: transform .3s;
          @include res(border-top-right-radius,null,(xs:.4rem));
          @include res(border-top-left-radius,null,(xs:.4rem));
          @include res(background-color,null,(xs:#fff));
          @include res(left,null,(xs:0));
          @include res(top,null,(xs:100%));
          @include res(z-index,null,(xs:9));
          @include res(position,static,(xs:fixed));
          @include res(padding,null,(xs:.3rem));
          @include res(padding-top,null,(xs:1.2rem));
          &.show-mob{
            @include res(transform,translateY(0),(xs:translateY(-100%)));
          }
          .text-title{
            position: absolute;
            top: 0;
            left: 0;
            text-align: center;
            width: 100%;
            border-bottom: 1px solid #f2f2f2;
            background-color: #fff;
            padding: 10px 0;
            @include res(font-size,null,(xs:.32rem));
            @include res(display,none,(xs:block));
            .iconfont{
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 .3rem;
            }
            .done-text{
              position: absolute;
              top: 0;
              right: 0;
              color: $color-main;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 .3rem;
            }
          }
        }
        textarea{
          flex-grow: 1;
          min-width: 100px;
          border: none;
          background-color: transparent;
          width: 100%;
          @include res(background-color,transparent,(xs:#ededed));
          // @include res(text-align,left,(xs:right));
          @include res(font-size,.16rem,(md:16px,xs:14px));
          @include res(padding-left,.2rem,(md:10px));
          @include res(padding-top,.2rem,(md:10px));
          @include res(padding-bottom,.2rem,(md:10px));
          @include res(padding-right,0,(md:10px));
        }
      }
      .item-select{
        flex-shrink: 0;
        @include res(width,calc(100% - 1.75rem),(md:calc(100% - 170px),xs:100%));
        @include res(display,flex);
        .select{
          position: relative;
          flex-grow: 1;
          // flex-shrink: 0;
          @include res(box-shadow,0 0 .2rem rgba(0,0,0,.1),(xs:none));
          @include res(max-width,50%,(xs:100%));
          &:not(:last-child){
            @include res(margin-right,.1rem,(md:10px,xs:0));
            @include res(margin-bottom,0);
          }
          &::after{
            content: '\e813';
            font-family: 'iconfont';
            position: absolute;
            top: .01rem;
            right: .02rem;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            pointer-events: none;
            color: $color-main;
            pointer-events: none;
            @include res(font-size,.32rem,(md:24px));
            @include res(width,.6rem);
            @include res(height,calc(100% - .04rem));
          }
          select{
            appearance:none;
            -webkit-appearance:none;
            -moz-appearance:none;
            -ms-appearance:none;
            -o-appearance:none;
            -khtml-appearance:none;
            cursor: pointer;
            transition: border .2s;
            border: .01rem solid transparent;
            box-sizing: border-box;
            @include res(font-size, .16rem,(md:16px));
            @include res(height,.58rem,(md:40px));
            @include res(width,100%);
            @include res(padding-left,.2rem,(md:10px));
            @include res(padding-right,.6rem,(md:40px));
            &::-ms-expand {
              display: none;
            }
            &:focus{
              border: .01rem solid rgba(56,77,195,.5);
            }
          }
          input{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            border: none;
            outline: none;
            cursor: pointer;
            width: 100%;
            box-sizing: border-box;
            max-width: 100%;
            @include res(font-size,null,(xs:14px));
            @include res(padding-left,.2rem,(md:10px));
            @include res(padding-right,.6rem,(md:40px,xs:25px));
            @include res(height,.58rem,(md:40px,xs:30px));
            @include res(line-height,.58rem,(md:40px,xs:30px));
          }
          ul{
            display:none;
            position:absolute;
            z-index: 2;
            left:0;
            width: 100%;
            overflow-y: auto;
            border:1px solid #D4D4D4;
            border-bottom-right-radius:3px;
            border-bottom-left-radius:3px;
            background:#fff;
            box-sizing: border-box;
            @include res(min-width,134px,(xs:100%));
            @include res(max-height,300px,(xs:200px));
            @include res(top,.58rem,(md:40px,xs:30px));
            &::-webkit-scrollbar {
              width: 4px;    
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
              background: rgba(#BDBDBD,.6);
            }
            &::-webkit-scrollbar-track {
              box-shadow: inset 0 0 5px rgba(0,0,0,0);
              border-radius: 0;
              background: rgba(0,0,0,0);
            }
            li{
              cursor: pointer;
              transition: all .3s;
              padding: 0 10px;
              line-height: 1;
              @include res(padding, 10px 10px,(xs: 8px 10px));
              @include res(font-size,null,(xs:.26rem));
              &:hover{
                color:#fff;
                background:#6AA7EA;
              }
              &.cur{
                color:#fff;
                background:#195DA3;
              }
              &.disable{
                background-color: #eaeaea;
                pointer-events: none;
              }
            }
          }
        }
      }
      .item-radio{
        @include res(width,calc(100% - 1.75rem),(md:calc(100% - 160px),xs:100%));
        @include res(font-size, .18rem,(md:16px,xs:14px));
        >label{
          display: inline-flex;
          align-items: center;
          cursor: pointer;
          @include res(margin-right,.2rem,(md:10px));
          @include res(margin-bottom,.1rem,(md:5px));
          input[type=radio]{
            @include res(width, .16rem,(md:16px));
            @include res(height, .16rem,(md:16px));
            @include res(margin-right,.08rem,(md:5px));
          }
        }
      }
      .item-btn{
        flex-grow: 1;
        button{
          width: 100%;
          border: none;
          background-color: $color-main;
          color: #fff;
          box-shadow: 0 .1rem .15rem rgba(56,77,195,.1);
          cursor: pointer;
          transition: background-color .4s;
          padding: 0;
          @include res(font-size,.18rem,(md:16px));
          @include res(height,.58rem,(md:40px));
          &:hover{
            background-color: rgba(56,77,195,.9);
          }
        }
      }
    }
  }
}

.switch-btn{
  border: .01rem solid #cccccc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @include res(width,1.56rem,(md:120px,sm:100px));
  @include res(height,.56rem,(md:40px,xs:36px));
  @include res(font-size,.2rem,(md:16px,sm:14px));
  .iconfont{
    @include res(font-size,.22rem);
    @include res(margin-right,.1rem,(md:6px));
  }
  .img-switch{
    display: none;
  }
  &.show-text-list{
    .text-switch{
      display: none;
    }
    .img-switch{
      display: block;
    }
  }
}

// 产品列表图文
.pro-list{
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  @include res(display,flex);
  .pro-list-item{
    @include res(width,23%,(sm:47%,xs:48%));
    @include res(margin-bottom,.4rem);
    &:hover{
      .item-img img{
        transform: translateY(-10%);
      }
      .item-t .item-link span{
        background-color: $color-main;
        color: #fff;
        border: .01rem solid $color-main;
      }
    }
    .item-img{
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      @include res(height,1.7rem,(md:105px,sm:135px,xs:90px));
      @include res(padding-left,.3rem);
      @include res(padding-right,.3rem);
      &::after{
        content: '';
        display: block;
        width: 100%;
        height: 40%;
        background-color: #f8f8f8;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
      }
      img{
        display: block;
        max-width: 90%;
        max-height: 90%;
        width: auto;
        height: auto;
        transition: transform .6s;
        margin: 0 auto;
      }
    }
    .item-t{
      text-align: center;
      background-color: #f8f8f8;
      @include res(padding-left,.3rem,(xs:5px));
      @include res(padding-right,.3rem,(xs:5px));
      @include res(padding-top,.5rem,(xs:15px));
      @include res(padding-bottom,.4rem,(xs:10px));
      .item-title{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        @include res(font-size,.24rem,(md:18px,xs:16px));
        @include res(margin-bottom,.1rem);
        &::after{
          content: '';
          height: .02rem;
          background-color: $color-main;
          margin: 0 auto;
          @include res(display,block,(xs:none));
          @include res(width,.3rem);
          @include res(margin-top,.12rem);
        }
      }
      .item-desc{
        color: #999999;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        @include res(min-height,.52rem,(md:52px));
        @include res(display,-webkit-box,(xs:none));
      }
      .item-link{
        font-weight: bold;
        @include res(display,null,(xs:none));
        @include res(margin-top,.25rem);
        span{
          display: inline-block;
          border: .01rem solid #959595;
          transition: color .4s,background-color .4s,border .4s;
          @include res(padding-left,.25rem);
          @include res(padding-right,.25rem);
          @include res(padding-top,.06rem);
          @include res(padding-bottom,.06rem);
          @include res(font-size,.16rem,(md:14px));
          .iconfont{
            font-size: .14rem;
          }
        }
      }
    }
  }
  .placeholder{
    @include res(width,23%);
  }
}
// 产品列表
.text-list-show{
  .pro-list{
    display: block;
    .pro-list-item{
      width: 100%;
      position: relative;
      border-bottom: .01rem solid #eeeeee;
      margin-bottom: 0;
      &::after{
        content: '';
        display: block;
        width: 200%;
        height: 100%;
        background-color: #f8f8f8;
        position: absolute;
        bottom: 0;
        left: -50%;
        z-index: -1;
        opacity: 0;
        transition: opacity .3s;
      }
      &:hover{
        &::after{
          opacity: 1;
        }
        .item-img img{
          transform: translateY(0);
        }
        .item-t{
          .item-title{
            color: $color-main;
          }
        }
      }
      a{
        display: flex;
        align-items: center;
      }
      .item-img{
        flex-shrink: 0;
        @include res(height,1.7rem,(md:105px,sm:100px,xs:80px));
        @include res(width,2.5rem,(md:150px,sm:110px));
        @include res(padding-left,.3rem,(xs:0));
        @include res(padding-right,.3rem,(xs:10px));
        &::after{
          display: none;
        }
      }
      .item-t{
        text-align: left;
        background-color: transparent;
        align-items: center;
        justify-content: space-between;
        @include res(width,calc(100% - 2.5rem),(md:calc(100% - 150px),xs:calc(100% - 110px)));
        @include res(display,flex);
        @include res(padding-left,.3rem,(xs:0));
        @include res(padding-right,.3rem,(xs:0));
        @include res(padding-top,0);
        @include res(padding-bottom,0);
        .item-title{
          transition: color .3s;
          @include res(font-size,.24rem,(md:18px,xs:16px));
          @include res(margin-bottom,0);
          &::after{
            display: none;
          }
        }
        .item-desc{
          display: none;
        }
        .item-link{
          flex-shrink: 0;
          text-align: right;
          @include res(display,null,(xs:block));
          @include res(margin-left,.2rem,(md:10px));
          @include res(margin-top,0);
          &::after{
            content: '\e8d4';
            font-family: 'iconfont';
            @include res(display,none,(sm:block));
            @include res(font-size,12px);
          }
          span{
            @include res(display,null,(sm:none));
            @include res(font-size,.16rem,(md:14px,xs:12px));
          }
        }
      }
    }
  }
}

// 解决方案列表图文
.solution-list{
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  @include res(display,flex,(xs:block));
  .solution-list-item{
    @include res(width,23%,(sm:47%,xs:100%));
    @include res(margin-bottom,.4rem);
    &:hover{
      .item-img b{
        transform: scale(1.05);
      }
      .item-t .item-link span{
        background-color: $color-main;
        color: #fff;
        border: .01rem solid $color-main;
      }
    }
    .item-t{
      text-align: center;
      background-color: #f8f8f8;
      @include res(padding-left,.3rem);
      @include res(padding-right,.3rem);
      @include res(padding-top,.3rem);
      @include res(padding-bottom,.4rem);
      .item-title{
        @include res(font-size,.24rem,(md:18px));
        @include res(margin-bottom,.1rem);
        &::after{
          content: '';
          display: block;
          background-color: $color-main;
          margin: 0 auto;
          @include res(height,.02rem,(md:2px));
          @include res(width,.3rem);
          @include res(margin-top,.12rem);
        }
      }
      .item-desc{
        color: #999999;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      .item-link{
        font-weight: bold;
        @include res(margin-top,.25rem);
        span{
          display: inline-block;
          border: .01rem solid #959595;
          transition: color .4s,background-color .4s,border .4s;
          @include res(padding-left,.25rem);
          @include res(padding-right,.25rem);
          @include res(padding-top,.06rem);
          @include res(padding-bottom,.06rem);
          .iconfont{
            font-size: .14rem;
          }
        }
      }
    }
  }
  .placeholder{
    @include res(width,23%);
  }
}

// 解决方案列表
.text-list-show{
  .solution-list{
    display: block;
    .solution-list-item{
      width: 100%;
      position: relative;
      border-bottom: .01rem solid #eeeeee;
      margin-bottom: 0;
      &::after{
        content: '';
        display: block;
        width: 200%;
        height: 100%;
        background-color: #f8f8f8;
        position: absolute;
        bottom: 0;
        left: -50%;
        z-index: -1;
        opacity: 0;
        transition: opacity .3s;
      }
      &:hover{
        &::after{
          opacity: 1;
        }
        .item-t{
          .item-title{
            color: $color-main;
          }
        }
      }
      a{
        display: flex;
        align-items: center;
        @include res(padding-top,.2rem,(md:10px));
        @include res(padding-bottom,.2rem,(md:10px));
      }
      .item-img{
        @include res(width,2rem,(md:120px,sm:90px));
      }
      .item-t{
        text-align: left;
        background-color: transparent;
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include res(padding-left,.3rem);
        @include res(padding-right,.3rem);
        @include res(padding-top,.3rem,(md:10px));
        @include res(padding-bottom,.4rem,(md:10px));
        .item-title{
          transition: color .3s;
          @include res(font-size,.24rem,(md:18px));
          @include res(margin-bottom,0px);
          &::after{
            display: none;
          }
        }
        .item-desc{
          display: none;
        }
        .item-link{
          flex-shrink: 0;
          text-align: right;
          @include res(margin-left,.2rem,(md:10px));
          @include res(margin-top,0);
          &::after{
            content: '\e8d4';
            font-family: 'iconfont';
            @include res(display,none,(sm:block));
            @include res(font-size,12px);
          }
          span{
            @include res(display,null,(sm:none));
            @include res(font-size,.16rem,(md:14px,xs:12px));
          }
        }
      }
    }
  }
}

// 新闻列表图文
.news-list{
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  @include res(display,flex,(xs:block));
  .news-list-item{
    flex-shrink: 0;
    background-color: #f8f8f8;
    @include res(width,30%,(md:32%,xs:100%));
    @include res(margin-bottom,1rem,(md:15px,xs:10px));
    &:hover{
      .item-img b{
        transform: scale(1.1);
      }
      .item-t{
        .item-title{
          color: $color-main;
        }
        .item-date .more{
          color: $color-main;
        }
      }
    }
    .item-t{
      @include res(padding-top, .25rem);
      @include res(padding-bottom, .25rem);
      @include res(padding-left, .25rem);
      @include res(padding-right, .25rem);
      .item-title{
        font-weight: bold;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        word-break: break-all;
        white-space: normal !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        transition: color .3s;
        @include res(margin-bottom, .3rem,(md:10px));
        @include res(height,.715rem,(md: 58.5px,sm:52px,xs:auto));
        @include res(font-size, .22rem,(md:18px,sm:16px,xs:16px));
        .iconfont{
          opacity: 0;
          @include res(margin-left,.15rem,(md:10px, xs:5px));
        }
      }
      .item-date{
        align-items: center;
        justify-content: space-between;
        color: #666666;
        @include res(font-size,.16rem,(md:14px));
        @include res(display, flex);
        .date{
          transition: color .3s;
        }
        .more{
          transition: color .3s;
          text-transform: uppercase;
        }
      }
    }
  }
  .placeholder{
    @include res(width,30%,(xs:100%));
  }
  &+.pages{
    margin-top: 0;
  }
}
// 新闻列表
.text-list-show{
  .news-list{
    display: block;
    @include res(margin-bottom,.7rem,(md:40px,xs:20px));
    .news-list-item{
      width:100%;
      border-bottom: .01rem solid #eeeeee;
      margin-bottom: 0;
      background-color: transparent;
      position: relative;
      z-index: 1;
      &::after{
        content: '';
        display: block;
        width: 200%;
        height: 100%;
        background-color: #f8f8f8;
        position: absolute;
        bottom: 0;
        left: -50%;
        z-index: -1;
        opacity: 0;
        transition: opacity .3s;
      }
      &:hover{
        &::after{
          opacity: 1;
        }
        .item-t{
          .item-date .date{
            color: $color-main;
          }
        }
      }
      .item-img{
        display: none;
      }
      .item-t{
        align-items: center;
        justify-content: space-between;
        width: 100%;
        @include res(display,flex);
        @include res(padding-right,null,(xs:0));
        @include res(padding-left,null,(xs:0));
        .item-title{
          height: auto;
          margin-bottom: 0;
          @include res(font-weight,null,(xs:normal));
          .iconfont{
            opacity: 1;
            @include res(display,none,(md:none));
          }
          p{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            // @include res(display,inline-block,(md:block));
          }
        }
        .item-date{
          flex-shrink: 0;
          @include res(margin-left,50px,(sm:10px));
          @include res(text-align,null,(xs:right));
          @include res(display,null,(xs:block));
          .more{
            display: none;
          }
        }
      }
    }
  }
}

// 视频中心列表图文
.vedio-list{
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  @include res(display,flex,(xs:block));
  .vedio-list-item{
    flex-shrink: 0;
    background-color: #f8f8f8;
    @include res(width,30%,(xs:100%));
    @include res(margin-bottom,1rem,(md:30px,xs:10px));
    &:hover{
      .item-img b{
        transform: scale(1.1);
      }
      .item-t{
        .item-title{
          color: $color-main;
        }
        .item-date .more{
          color: $color-main;
        }
      }
    }
    .item-img{
      .play-btn{
        border-radius: 50%;
        background-color: #fff;
        position: absolute;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-main;
        @include res(bottom, .15rem,(md:10px));
        @include res(right, .2rem,(md:10px));
        @include res(width,.38rem,(md:30px));
        @include res(height,.38rem,(md:30px));
      }
    }
    .item-t{
      @include res(padding-top, .25rem);
      @include res(padding-bottom, .25rem);
      @include res(padding-left, .25rem);
      @include res(padding-right, .25rem);
      .item-title{
        font-weight: bold;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        word-break: break-all;
        white-space: normal !important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        transition: color .3s;
        @include res(margin-bottom, .3rem,(md:10px));
        @include res(height,.715rem,(md: 58.5px));
        @include res(font-size, .22rem,(md:18px));
        .iconfont{
          opacity: 0;
          @include res(margin-left,.15rem,(md:10px, xs:5px));
        }
      }
      .item-date{
        align-items: center;
        justify-content: space-between;
        color: #666666;
        @include res(font-size,.16rem,(md:14px));
        @include res(display, flex);
        .date{
          transition: color .3s;
        }
        .more{
          transition: color .3s;
          text-transform: uppercase;
        }
      }
    }
  }
  .placeholder{
    @include res(width,30%,(xs:100%));
  }
  &+.pages{
    margin-top: 0;
  }
}
// 视频中心列表
.text-list-show{
  .vedio-list{
    display: block;
    @include res(margin-bottom,.7rem,(md:40px,xs:20px));
    .vedio-list-item{
      width:100%;
      border-bottom: .01rem solid #eeeeee;
      margin-bottom: 0;
      background-color: transparent;
      position: relative;
      z-index: 1;
      @include res(padding-top, .3rem,(md:15px,xs:0));
      @include res(padding-bottom, .3rem,(md:15px,xs:0));
      &::after{
        content: '';
        display: block;
        width: 200%;
        height: 100%;
        background-color: #f8f8f8;
        position: absolute;
        bottom: 0;
        left: -50%;
        z-index: -1;
        opacity: 0;
        transition: opacity .3s;
      }
      &:hover{
        &::after{
          opacity: 1;
        }
        .item-t{
          .item-date .date{
            color: $color-main;
          }
        }
      }
      a{
        align-items: center;
        @include res(display,flex);
      }
      .item-img{
        flex-shrink: 0;
        @include res(width,2rem);
        @include res(display,null);
        .play-btn{
          bottom: 50%;
          right: 50%;
          transform: translate3d(50%,50%,0);
        }
      }
      .item-t{
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;
        @include res(display,flex,(xs:block));
        .item-title{
          height: auto;
          margin-bottom: 0;
        }
        .item-date{
          flex-shrink: 0;
          @include res(margin-left,.2rem,(md:10px));
          @include res(text-align,null,(xs:right));
          @include res(display,null,(xs:block));
          .more{
            display: none;
          }
        }
      }
    }
  }
}

//下载列表
.download-list{
  border-top: .01rem solid #e9e9e9;
  @include res(margin-bottom,1rem,(md:10px));
  .download-list-item{
    align-items: center;
    flex-wrap: wrap;
    border-bottom: .01rem solid #e9e9e9;
    @include res(display,flex);
    @include res(padding-top,.22rem,(md:10px));
    @include res(padding-bottom,.22rem,(md:10px));
    >div{
      flex-shrink: 0;
      .ny-common-link{
        .iconfont{
          margin-left: 0;
          @include res(font-size,.18rem,(md:16px));
          @include res(margin-right,.1rem,(md:5px));
        }
      }
      &:not(:last-child){
        @include res(margin-bottom,0,(xs:10px));
      }
      &:nth-child(1){
        @include res(width,30%,(md:50%,xs:100%));
      }
      &:nth-child(2){
        @include res(width,10%,(md:10%,xs:20%));
      }
      &:nth-child(3){
        @include res(width,15%,(md:20%,xs:40%));
      }
      &:nth-child(4){
        @include res(width,15%,(md:20%,xs:40%));
      }
      &:nth-child(5){
        @include res(text-align,right);
        @include res(width,30%,(md:100%));
        @include res(margin-top,0,(md:15px,xs:0));
      }
    }
  }
}


.fancybox-button{
  @include res(width,54px,(md:44px));
  @include res(height,54px,(md:44px));
  &.fancybox-button--zoom{
    position: relative;
    &::after{
      content: '\ec32';
      font-family: 'iconfont';
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      color: rgba(255,255,255,.8);
      transform: translate3d(-50%,-50%,0);
      transition: all .3s;
      @include res(font-size,28px,(md:22px));
    }
    &:hover{
      &::after{
        color: rgba(255,255,255,1);
      }
    }
    svg{
      display: none;
    }
  }
}