@import "../../assets/styles/utils";

.ny-banner{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  overflow: hidden;
  @include res(padding-top, .6rem);
  @include res(margin-top,-.4rem,(md:0));
  @include res(height,7.8rem,(xs:6.8rem));
  &::after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(7,29,50,.2);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .bg-img{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
    @include res(display,block,(xs:none));
  }
  .bg-img-mob{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
    @include res(display,none,(xs:block));
  }
  .video{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    &::after{
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.2);
      position: absolute;
      top: 0;
      left: 0;
    }
    &.pc{
      @include res(display,block,(xs:none));
    }
    &.mob{
      @include res(display,none,(xs:block));
    }
    video{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .t-cont{
    position: relative;
    z-index: 2;
    text-align: center;
    h1{
      @include res(font-size, .6rem);
      @include res(margin-bottom,.25rem);
      &::after{
        content: '';
        display: block;
        background-color: #fff;
        margin: 0 auto;
        @include res(width,.46rem);
        @include res(height,.04rem);
        @include res(margin-top,.15rem);
      }
    }
    .desc{
      @include res(max-width,7.4rem,(sm:8rem));
      @include res(padding-left,.15rem,(xs:25px));
      @include res(padding-right,.15rem,(xs:25px));
      @include res(font-size, .26rem,(md:16px));
    }
    .link{
      @include res(margin-top,.6rem);
    }
  }
}