@import "../../assets/styles/utils";

.apply-quotation{
  background: url(./images/apply_quotation_bg.jpg) no-repeat center center;
  background-size: cover;
  text-align: center;
  color: #fff;
  @include res(padding-top,.5rem);
  @include res(padding-bottom,.5rem);
  .title{
    @include res(font-size,.48rem);
    @include res(margin-bottom, .25rem);
  }
  .desc{
    @include res(font-size,.24rem,(md:16px));
    @include res(margin-bottom, .35rem);
  }
  .link{
    a{
      display: inline-flex;
      align-items: center;
      border: .01rem solid rgba(255,255,255,.2);
      transition: background-color .4s;
      @include res(padding-left,.5rem);
      @include res(padding-right,.5rem);
      @include res(padding-top,.25rem);
      @include res(padding-bottom,.25rem);
      @include res(font-size,.2rem,(md:14px));
      .iconfont{
        opacity: .6;
        transition: transform .4s;
        @include res(font-size, .22rem);
        @include res(margin-left,.12rem);
      }
      &:hover{
        background-color: rgba(255,255,255,.2);
        .iconfont{
          transform: translateX(.05rem);
        }
      }
    }
  }
}