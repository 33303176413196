@import "../../assets/styles/utils";

footer{
  background: #f2f2f2;
  background-size: cover;
  @include res(overflow,hidden,(xs:visible));
  .footer-top{
    align-items: center;
    justify-content: space-between;
    border-bottom: solid #d9d9d9;
    flex-wrap: wrap;
    @include res(border-width,0.01rem,(md:1px));
    @include res(display,flex,(xs:block));
    @include res(padding-top,0.55rem);
    @include res(padding-bottom,0.4rem);
    .crumbs{
      align-items: center;
      justify-content: flex-start;
      color: #797979;
      @include res(display,flex,(xs:none));
      @include res(font-size,0.24rem,(md:18px));
      @include res(margin-bottom,0,(xs:0.1rem));
      a{
        font-weight: bold;
        transition: color .4s;
        &:hover{
          color: $color-main;
        }
      }
      span{
        @include res(font-size,0.18rem,(md:14px));
        @include res(margin-left,0.2rem);
        @include res(margin-right,0.2rem);
      }
    }
    .btm-search-form{
      @include res(width,34%,(xs:100%));
      form{
        display: flex;
        align-items: stretch;
        input{
          flex-grow: 1;
          border: solid #d9d9d9;
          transition: border .4s;
          background: transparent;
          @include res(border-width,0.01rem,(md:1px));
          @include res(font-size, null,(md:14px));
          @include res(padding-left,0.2rem);
          &:focus{
            border: solid $color-main;
            @include res(border-width,0.01rem,(md:1px));
          }
        }
        button{
          background: #e4e4e4;
          border: none;
          cursor: pointer;
          transition: background .4s,color .4s;
          color: #9f9f9f;
          flex-shrink: 0;
          @include res(width,0.6rem,(md:40px));
          @include res(height,0.6rem,(md:40px));
          .iconfont{
            @include res(font-size,0.28rem,(md:20px));
          }
          &:hover{
            background: $color-main;
            color: #fff;
          }
        }
      }
    }
  }
  .footer{
    justify-content: space-between;
    @include res(padding-top,0.45rem);
    @include res(padding-bottom, 0.5rem);
    @include res(display,flex);
    @include res(flex-direction,row,(sm:column-reverse));
    .left-info{
      box-sizing: border-box;
      @include res(padding-right,0.2rem);
      @include res(width,34%,(sm:100%));
      @include res(margin-top,0,(sm:20px));
      .logo{
        @include res(display,null,(md:none));
        @include res(text-align,left,(xs:center));
        @include res(margin-bottom,0.6rem);
        img{
          width: 2.82rem;
          max-width: 100%;
          height: auto;
        }
      }
      .email-form{
        @include res(display,null,(md:none));
        @include res(margin-bottom,0.3rem);
        form{
          display: flex;
          align-items: stretch;
          input{
            flex-grow: 1;
            border: solid #d9d9d9;
            transition: border .4s;
            background: transparent;
            @include res(border-width,0.01rem,(md:1px));
            @include res(padding-left,0.2rem);
            @include res(font-size, null,(md:14px));
            &:focus{
              border: solid $color-main;
              @include res(border-width,0.01rem,(md:1px));
            }
          }
          button{
            background: #e4e4e4;
            border: none;
            cursor: pointer;
            transition: background .4s,color .4s;
            color: #b6b6b6;
            flex-shrink: 0;
            @include res(width,0.6rem,(md:40px));
            @include res(height,0.6rem,(md:40px));
            .iconfont{
              @include res(font-size,0.28rem,(md:20px));
            }
            &:hover{
              background: $color-main;
              color: #fff;
            }
          }
        }
      }
      .call{
        color: $color-text;
        @include res(text-align,left,(sm:center));
        @include res(margin-right,0,(sm:0));
        .p1{
          @include res(font-size,0.16rem,(md:16px));
        }
        .p2{
          font-family: 'din';
          color: #000;
          @include res(font-size, 0.38rem,(md:22px,sm:30px));
        }
        .p3{
          @include res(font-size, 0.16rem,(md:16px));
        }
      }
      .media-icon{
        display: flex;
        align-items: center;
        @include res(justify-content,flex-start,(sm:center));
        @include res(text-align,left,(sm:center));
        @include res(margin-top,0.45rem);
        >div{
          position: relative;
          flex-shrink: 0;
          border-radius: 50%;
          background-color: #ededed;
          justify-content: center;
          align-items: center;
          transition: background-color .5s;
          @include res(width,0.54rem,(md:40px,sm:50px,xs:.8rem));
          @include res(height,0.54rem,(md:40px,sm:50px,xs:.8rem));
          @include res(display,flex);
          &:not(:last-child){
            @include res(margin-right,0.2rem);
          }
          &:hover{
            background-color: $color-main;
            .iconfont{
              color: #fff;
            }
            .ewm{
              opacity: 1;
              visibility: visible;
              transform: translateX(-50%) translateY(0px);
            }
          }
          .iconfont{
            line-height: 1;
            transition: color .5s;
            color: rgba(0,0,0,.5);
            cursor: pointer;
            @include res(font-size,0.4rem,(md:30px,sm:40px,xs:.68rem));
          }
          .ewm{
            position: absolute;
            bottom: 130%;
            left: 50%;
            transition: opacity .5s,visibility .5s,transform .5s;
            opacity: 0;
            visibility: hidden;
            z-index: 3;
            transform: translateX(-50%) translateY(-5px);
            &::after{
              content: '';
              display: block;
              border: 0.08rem solid;
              border-color: #fff transparent transparent transparent;
              position: absolute;
              left: calc(50% - 0.08rem);
              top: 100%;
            }
            img{
              display: block;
              height: auto;
              @include res(width,1.45rem);
            }
          }
        }
      }
    }
    .right-nav{
      box-sizing: border-box;
      align-items: flex-start;
      justify-content: space-between;
      @include res(display,flex,(xs:block));
      @include res(width,50%,(sm:100%));
      ul{
        flex-shrink: 0;
        @include res(margin-bottom,0,(xs:10px));
        @include res(border-bottom,none,(xs:1px solid #dadadd));
        &.active{
          .nav-title{
            .iconfont{
              transform: rotate(0deg);
            }
          }
        }
        .nav-title{
          color: #000;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: space-between;
          @include res(font-size,0.2rem,(md:16px));
          @include res(margin-bottom,0.15rem,(xs:0));
          a{
            flex-grow: 1;
            @include res(line-height,null,(xs:50px));
          }
          .iconfont{
            width: 50px;
            height: 50px;
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            transform-origin: center;
            transform: rotate(180deg);
            font-size: 14px;
            transition: all .3s;
            @include res(display,none,(xs:flex));
          }
        }
        .sub-title{
          @include res(display,block,(xs:none));
          @include res(padding-bottom,0,(xs:10px));
          @include res(padding-left,0,(xs:20px));
        }
        li{
          white-space: nowrap;
          @include res(font-size, 0.16rem,(md:14px));
          @include res(margin-top, 0.1rem);
          a{
            transition: color .3s;
            &:hover{
              color: $color-main;
            }
          }
        }
      }
    }
  }
  .friend-link{
    display: none;
    color: rgba(0,0,0,.6);
    border-top: solid rgba(0,0,0,.1);
    @include res(border-width,0.01rem,(md:1px));
    @include res(font-size,0.14rem,(md:14px));
    @include res(padding-top,0.25rem);
    @include res(padding-bottom,0.25rem);
    .cont{
      position: relative;
      display: flex;
      align-items: flex-start;
      &:hover{
        .link-title{
          .iconfont{
            transform: translateY(-50%) rotate(-90deg);
          }
        }
        .link{
          @include res(visibility,null,(xs:visible));
          @include res(opacity,null,(xs:1));
          @include res(transform,translateY(0),(xs:translateY(0px)));
        }
      }
      .link-title{
        flex-shrink: 0;
        .iconfont{
          position: absolute;
          top: 50%;
          right: 0.1rem;
          transform: translateY(-50%);
          transition: transform .4s;
          transform-origin: center;
          @include res(display,none,(xs:block));
          @include res(font-size, 0.14rem,(md:14px));
        }
        span{
          @include res(display,null,(xs:none));
        }
      }
      .link{
        bottom: 150%;
        left: 0;
        transition: opacity .4s,visibility .4s,transform .4s;
        z-index: 2;
        @include res(visibility,null,(xs:hidden));
        @include res(opacity,null,(xs:0));
        @include res(transform,translateY(0),(xs:translateY(-0.1rem)));
        @include res(border-radius,0,(xs:0.1rem));
        @include res(background-color,null,(xs:#fff));
        @include res(overflow-y,null,(xs:auto));
        @include res(max-height,null,(xs:3rem));
        @include res(width,null,(xs:100%));
        @include res(position,static,(xs:absolute));
        @include res(box-shadow,null,(xs:0 0 0.1rem rgba(0,0,0,.1)));
        a{
          position: relative;
          transition: color .4s;
          @include res(color,rgba(0,0,0,.6),(xs:$color-text));
          @include res(display,inline-block,(xs:block));
          @include res(padding-left,null,(xs:0.3rem));
          @include res(padding-right,null,(xs:0.3rem));
          @include res(padding-top,null,(xs:0.1rem));
          @include res(padding-right,null,(xs:0.1rem));
          &:hover{
            @include res(color,rgba(0,0,0,1),(xs:$color-main));
          }
          &:not(:last-child){
            @include res(padding-right,0.13rem,(xs:auto));
            @include res(margin-right,0.07rem,(xs:auto));
            &::after{
              content: '|';
              position: absolute;
              top: 0;
              right: 0;
              transform: scaleY(.8);
              transform-origin: center;
              @include res(display,null,(xs:none));
            }
          }
        }
      }
    }
  }
  .copyright{
    background-color: #e6e6e6;
    position: relative;
    align-items: center;
    justify-content: space-between;
    color: #656565;
    @include res(font-size,0.14rem,(xs:0.12rem));
    @include res(display,flex,(md:block));
    @include res(text-align,null,(md:center));
    @include res(padding-bottom, 0.2rem,(xs:1.2rem));
    @include res(padding-top, 0.2rem);
    &::before,&::after{
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: #e6e6e6;
      position: absolute;
      top: 0;
    }
    &::before{
      @include res(right,calc(100% - 0.01rem),(md:calc(100% - 1px)));
    }
    &::after{
      @include res(left,calc(100% - 0.01rem),(md:calc(100% - 1px)));
    }
    a{
      transition: color .4s;
      &:hover{
        color: #000;
      }
    }
    .left{
      @include res(margin-bottom,0,(md:10px));
      a{
        &:not(:last-child){
          @include res(margin-right,0.35rem);
        }
        &.site-map{
          @include res(display,null,(md:none));
        }
      }
    }
  }
}