@import "../../assets/styles/utils";

.authority,.authoritydown{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1001;
  display: none;
  .authority-mask{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.6);
    z-index: 0;
  }
  .cont{
    position: absolute;
    z-index: 1;
    background-color: #fff;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate3d(-50%,-50%,0);
    @include res(max-width,5.2rem,(md:360px));
    @include res(width,90%);
    @include res(padding-top,.4rem,(md:30px));
    @include res(padding-bottom,.4rem,(md:30px));
    @include res(padding-left,.5rem,(md:30px));
    @include res(padding-right,.5rem,(md:30px));
    .close-btn{
      position: absolute;
      line-height: 1;
      cursor: pointer;
      @include res(top,.1rem,(md:10px));
      @include res(right,.1rem,(md:10px));
      &:hover{
        .iconfont{
          transform: rotate(90deg);
        }
      }
      .iconfont{
        display: inline-block;
        transition: all .3s;
        transform-origin: center;
        @include res(font-size,.4rem,(md:30px));
      }
    }
  }
  .icon{
    background-color: #cb4444;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin: 0 auto;
    @include res(width,.8rem,(md:60px,xs:50px));
    @include res(height,.8rem,(md:60px,xs:50px));
    .iconfont{
      @include res(font-size,.5rem,(md:40px,xs:34px));
    }
  }
  .title{
    color: #000;
    @include res(letter-spacing,3px, 1 / 3);
    @include res(font-size,.24rem,(md:22px,xs:20px));
    @include res(margin-top,.3rem,(md:20px));
    @include res(margin-bottom,.3rem,(md:20px));
  }
  .btn{
    a{
      display: block;
      background-color: $color-main;
      color: #fff;
      transition: all .3s;
      box-shadow: 0 10px 10px rgba($color-main,.2);
      @include res(font-size,.18rem,(md:18px,xs:16px));
      @include res(padding-top,.15rem,(md:10px));
      @include res(padding-bottom,.15rem,(md:10px));
      &:hover{
        background-color: $color-main-dark;
      }
    }
  }
}