@import "../../assets/styles/utils";

header{
  .pc-header{
    position: relative;
    z-index: 1000;
    &:hover{
      .top-link{
        .show-cont{
          @include res(color,#000);
          @include res(background-color,#e3e3e3);
        }
      }
      .header{
        @include res(background-color,#fff);
        @include res(border-bottom,solid #e8e8e8);
        @include res(border-width,.01rem,(md:1px));
        .left-logo{
          img{
            &.logo-color{
              @include res(opacity,1);
            }
            &.logo-white{
              @include res(opacity,0);
            }
          }
        }
        .right-cont{
          .nav{
            ul li .nav-title{
              @include res(color,#000);
            }
          }
          .right-fun{
            .touxiang .login{
              @include res(color,#000,);
            }
            .nav-search{
              .open-search .iconfont{
                @include res(color,#666666);
              }
            }
            .hamburger>div, .hamburger>div:after, .hamburger>div:before, .hamburger>view, .hamburger>view:after, .hamburger>view:before{
              @include res(color,#4c4c4c);
            }
          }
        }
      }
    }
    &.active,&.mob-active{
      .top-link{
        .show-cont{
          color: #000;
          background-color: #e3e3e3;
        }
      }
      .header{
        background-color: #fff;
        border-bottom: solid #e8e8e8;
        @include res(border-width,.01rem,(md:1px));
        .left-logo{
          img{
            &.logo-color{
              opacity: 1;
            }
            &.logo-white{
              opacity: 0;
            }
          }
        }
        .right-cont{
          .nav{
            ul li .nav-title{
              color: #000;
            }
          }
          .right-fun{
            .touxiang .login{
              color: #000;
            }
            .nav-search{
              .open-search .iconfont{
                color: #666666;
              }
            }
            .hamburger>div, .hamburger>div:after, .hamburger>div:before, .hamburger>view, .hamburger>view:after, .hamburger>view:before{
              background-color: #4c4c4c;
            }
          }
        }
      }
    }
    .top-link{
      &.active,&.scroll{
        .show-cont{
          color: #000;
          background-color: #e3e3e3;
        }
      }
      .show-cont{
        color: #fff;
        background-color: transparent;
        justify-content: flex-end;
        transition: color .4s,background-color .4s;
        position: relative;
        @include res(display,flex,(md:none));
        @include res(height,0.4rem);
        @include res(line-height,0.4rem);
        @include res(padding-left,4%, 5 / 4);
        @include res(padding-right,4%, 5 / 4);
        .gp{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          @include res(left,4%, 5 / 4);
        }
        .item{
          cursor: pointer;
          display: flex;
          align-items: center;
          transition: background-color .4s;
          @include res(padding-left,0.25rem);
          @include res(padding-right,0.25rem);
          &.itright{
            // padding-right: 0;
            margin-right: -0.25rem;
            cursor: inherit;
          }
          .iconfont{
            margin-top: 0.01rem;
            transition: transform .4s;
            @include res(font-size,0.14rem);
            @include res(margin-left,0.05rem);
          }
          &.active{
            background-color: #f2f2f2;
            .iconfont{
              transform: rotate(-90deg);
            }
          }
        }
      }
      .hidden-cont{
        .hidden-cont-item{
          display: none;
          .item-container{
            background-color: #f2f2f2;
            display: flex;
            justify-content: space-between;
            .item{
              flex-grow: 1;
              position: relative;
              @include res(padding-top,0.35rem);
              @include res(padding-bottom,0.3rem);
              @include res(padding-left,3%);
              @include res(padding-right,2%);
              &:hover{
                .title{
                  color: $color-main;
                  .iconfont{
                    @include res(margin-left,0.1rem);
                  }
                }
              }
              &:not(:last-child){
                &::after{
                  content: '';
                  background-color: rgba(0,0,0,.1);
                  position: absolute;
                  @include res(top, 30%);
                  @include res(right, 0);
                  @include res(width,.01rem,(md:1px));
                  @include res(height,40%);
                  @include res(display,block);
                }
              }
              .title{
                line-height: 1;
                transition: color .4s;
                @include res(margin-bottom,0.15rem);
                @include res(font-size,0.21rem);
                white-space: nowrap;
                .iconfont{
                  transition: margin-left .4s;
                  @include res(margin-left,0.06rem);
                }
              }
              .desc{
                color: $color-text;
              }
            }
          }
        }
      }
    }
    .header{
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      left: 0;
      width: 100%;
      transition: background-color .4s,transform .4s, border-bottom .4s; 
      @include res(top,100%,(md:0));
      @include res(position,absolute,(md:fixed));
      @include res(border-bottom,0.01rem solid transparent,(md:1px solid #e8e8e8));
      @include res(background-color,transparent,(md:#fff));
      @include res(padding-top,0,(md:15px));
      @include res(padding-bottom,0,(md:15px));
      @include res(padding-left,4%, 5 / 4);
      @include res(padding-right,4%, 5 / 4);
      &.trans{
        transform: translateY(-100%);
      }
      &.fixed{
        position: fixed;
        top: 0;
        background-color: #fff !important;
        border-bottom: solid #e8e8e8 !important;
        @include res(border-width,.01rem !important,(md:1px !important));
        .left-logo{
          img{
            &.logo-color{
              opacity: 1 !important;
            }
            &.logo-white{
              opacity: 0 !important;
            }
          }
        }
        .right-cont{
          margin-top: 0 !important;
          .nav{
            ul li .nav-title{
              color: #000 !important;
            }
          }
          .right-fun{
            .touxiang .login{
              color: #000 !important;
            }
            .nav-search{
              .open-search .iconfont{
                color: #666666 !important;
              }
            }
            .hamburger>div, .hamburger>div:after, .hamburger>div:before, .hamburger>view, .hamburger>view:after, .hamburger>view:before{
              background-color: #4c4c4c !important;
            }
          }
        }
      }
      &.active{
        background-color: #fff;
        border-bottom: solid #e8e8e8;
        @include res(border-width,.01rem,(md:1px));
        .left-logo{
          img{
            &.logo-color{
              opacity: 1;
            }
            &.logo-white{
              opacity: 0;
            }
          }
        }
        .right-cont{
          .nav{
            ul li .nav-title{
              color: #000;
            }
          }
          .right-fun{
            .touxiang .login{
              color: #000;
            }
            .nav-search{
              .open-search .iconfont{
                color: #666666;
              }
            }
            .hamburger>div, .hamburger>div:after, .hamburger>div:before, .hamburger>view, .hamburger>view:after, .hamburger>view:before{
              background-color: #4c4c4c;
            }
          }
        }
      }
      .left-logo{
        display: flex;
        align-items: center;
        a{
          display: block;
          position: relative;
        }
        img{
          display: block;
          height: auto;
          transition: opacity .4s;
          @include res(width,2.81rem);
          &.logo-color{
            position: absolute;
            top: 0;
            left: 0;
            @include res(opacity,0,(md:1));
          }
          &.logo-white{
            @include res(opacity,1,(md:0));
          }
        }
      }
      .right-cont{
        display: flex;
        align-items: center;
        transition: margin-top .4s;
        // @include res(margin-top,0.35rem);
        @media screen and(max-width:1024px) {
          margin-top: 0;
        }
        .nav{
          transition: opacity .4s,visibility .4s;
          transform-origin: center;
          height: 100%;
          @include res(display,block,(md:none));
          &.hide{
            opacity: 0;
            visibility: hidden;
          }
          ul{
            display: flex;
            height: 100%;
            li{
              height: 100%;
              &.show-sub,&.on,&.active{
                .nav-title{
                  .iconfont{
                    display: block;
                    transform: rotate(180deg) translateY(50%);
                  }
                  &::after{
                    transform: scaleX(1);
                  }
                }
              }
              .nav-title{
                font-weight: bold;
                color: #fff;
                transition: color .4s; 
                position: relative;
                height: 100%;
                @include res(line-height,0.38rem);
                @include res(font-size,0.18rem);
                @include res(margin-left,0.35rem);
                @include res(margin-right,0.35rem);
                // @include res(padding-right,18px, 10 / 18);
                a{
                  display: block;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  @include res(padding-top,0.35rem);
                  @include res(padding-bottom,0.35rem);
                }
                .iconfont{
                  font-size: 0.12rem;
                  margin-left: 0.05rem;
                  line-height: 1;
                  display: none;
                  position: absolute;
                  top: 50%;
                  left: calc(100%);
                  transform: translateY(-50%);
                  transform-origin: center;
                }
                &::after{
                  content: '';
                  display: block;
                  width: calc(100% + 0.3rem);
                  background-color: $color-main;
                  position: absolute;
                  left: -0.15rem;
                  bottom: -0.01rem;
                  transform: scaleX(0);
                  transform-origin: center;
                  @include res(height,.01rem,(md:1px));
                }
                &:hover{
                  .iconfont{
                    display: inline-block;
                  }
                }
              }
              .sub-nav{
                background-color: #ffffff;
                position: absolute;
                left: 0;
                width: 100%;
                display: none;
                @include res(padding-left, 0.8rem);
                @include res(padding-right, 0.8rem);
                @include res(padding-top, 0.6rem);
                // @include res(padding-bottom,10px);
                @include res(padding-bottom, 0.6rem);
                @include res(top, calc(100% + 0.01rem),(md:calc(100% + 1px)));
                .sub-nav-flex{
                  align-items: flex-start;
                  flex-direction: row-reverse;
                  @include res(display,flex);
                }
                &.pro{
                  .nav-pro-swiper{
                    flex-shrink: 0;
                    @include res(width,27%);
                    .swiper{
                      overflow: hidden;
                      position: relative;
                      .swiper-slide{
                        a{
                          display: block;
                          background-position: center;
                          background-size: cover;
                          background-repeat: no-repeat;
                        }
                        img{
                          display: block;
                          width: 100%;
                          height: auto;
                          opacity: 0;
                        }
                      }
                      .swiper-pagination{
                        text-align: left;
                        @include res(padding-left,0.3rem);
                        .swiper-pagination-bullet{
                          background: #fff;
                          position: relative;
                          @include res(margin-left,0.1rem);
                          @include res(margin-right,0.1rem);
                          @include res(width,0.1rem);
                          @include res(height,0.1rem);
                          svg{
                            position: absolute;;
                            z-index: 1;
                            transform-origin: center;
                            transform-box: fill-box;
                            transform: rotate(-90deg);
                            @include res(width,0.18rem);
                            @include res(height,0.18rem);
                            @include res(top,-.04rem);
                            @include res(left,-.04rem);
                            @include res(display,null,(xs:none));
                            circle {
                                stroke-width: 2;
                                fill: none;
                                stroke: #fff;
                                stroke-dasharray: 55px;
                                stroke-dashoffset: 55px;
                                cx: .09rem;
                                cy: .09rem;
                                r: .08rem;
                            }
                          }
                          &.swiper-pagination-bullet-active{
                            background: #fff;
                            svg{
                              circle{
                                  animation:4s circle linear;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  .nav-pro-icon{
                    flex-shrink: 0;
                    box-sizing: border-box;
                    @include res(padding-left,10%,(mmd:6%));
                    @include res(padding-right,10%,(mmd:6%));
                    @include res(width,73%);
                    .swiper{
                      overflow: hidden;
                      .swiper-slide{
                        display: flex;
                        flex-wrap: wrap;
                      }
                    }
                    .swiper-pagination{
                      position: static;
                      width: 100%;
                      text-align: center;
                      .swiper-pagination-bullet{
                        @include res(width,.1rem);
                        @include res(height,.1rem);
                        @include res(margin-left,.08rem);
                        @include res(margin-right,.08rem);
                        &.swiper-pagination-bullet-active{
                          background-color: $color-main;
                        }
                      }
                    }
                    a{
                      flex-shrink: 0;
                      text-align: center;
                      @include res(width, 20%);
                      @include res(margin-bottom,0.35rem);
                      @include res(margin-top,0.35rem);
                      &:hover{
                        .item-t{
                          color: $color-main;
                          &::after{
                            transform: scaleX(1);
                          }
                        }
                      }
                      .item-icon{
                        @include res(margin-bottom,0.2rem);
                        img{
                          width: 80%;
                          height: auto;
                          @include res(max-width,1.1rem);
                        }
                      }
                      .item-t{
                        position: relative;
                        transition: color .4s;
                        @include res(font-size, 0.18rem);
                        &::after{
                          content: '';
                          display: block;
                          width: 50%;
                          height: 0.02rem;
                          background-color: $color-main;
                          position: absolute;
                          top: 100%;
                          left: 25%;
                          transform-origin: center;
                          transition: transform .4s;
                          transform: scaleX(0);
                        }
                      }
                    }
                  }
                }
                &.solution{
                  .nav-solution-swiper{
                    flex-shrink: 0;
                    background-color: #f7f7f7;
                    box-sizing: border-box;
                    @include res(padding-left,0.4rem);
                    @include res(padding-right,0.4rem);
                    @include res(padding-top,0.3rem);
                    @include res(padding-bottom,0.3rem);
                    @include res(width,27%);
                    .swiper{
                      .swiper-slide{
                        .slide-title{
                          color: $color-main;
                          border-bottom:  solid rgba(0,0,0,.12);
                          @include res(border-width,0.01rem,(xs:1px));
                          @include res(padding-bottom,0.1rem);
                          @include res(font-size, 0.2rem);
                        }
                        .slide-subnav{
                          @include res(min-height,0.33rem);
                          a{
                            display: flex;
                            align-items: center;
                            transition: color .4s;
                            @include res(margin-top, 0.15rem);
                            &:hover{
                              color: $color-main;
                              .iconfont{
                                transform: translateX(0.05rem);
                              }
                            }
                            .iconfont{
                              font-size: 0.12rem;
                              transition: transform .4s;
                              @include res(margin-left, 0.1rem);
                            }
                          }
                        }
                      }
                    }
                  }
                  .nav-solution-icon{
                    flex-shrink: 0;
                    box-sizing: border-box;
                    display: flex;
                    flex-wrap: wrap;
                    @include res(width,73%);
                    @include res(padding-left,8%,(mmd:6%));
                    @include res(padding-right,10%,(mmd:6%));
                    a{
                      flex-shrink: 0;
                      text-align: center;
                      @include res(width,22%);
                      @include res(margin-right,3%);
                      @include res(margin-bottom, 0.6rem);
                      &:hover,&.active{
                        .item-t{
                          color: $color-main;
                          span::after{
                            transform: scaleX(1);
                          }
                        }
                      }
                      .item-img{
                        background-color: #f7f7f7;
                        @include res(margin-bottom,0.2rem);
                        img{
                          display: block;
                          margin: 0 auto;
                          max-width: 100%;
                          height: auto;
                        }
                      }
                      .item-t{
                        transition: color .4s;
                        @include res(font-size,0.18rem);
                        span{
                          position: relative;
                          display: inline-block;
                          &::after{
                            content: '';
                            display: block;
                            width: 110%;
                            position: absolute;
                            bottom: -0.02rem;
                            left: -5%;
                            height: 0.02rem;
                            background-color: $color-main;
                            transform-origin: center;
                            transform: scaleX(0);
                            transition: transform .4s;
                          }
                        }
                      }
                    }
                  }
                }
                &.about{
                  .about-nav{
                    box-sizing: border-box;
                    @include res(width, 100%);
                    @include res(display,flex);
                    @include res(padding-left,10%,(mmd:6%));
                    @include res(padding-right,5%,(mmd:3%));
                    .about-nav-item{
                      flex-shrink: 0;
                      @include res(width,17%);
                      @include res(margin-right,3%);
                      .nav-item-title{
                        display: flex;
                        align-items: center;
                        border-bottom: 0.01rem solid rgba(0,0,0,.1);
                        transition: color .4s;
                        @include res(font-size,0.28rem);
                        @include res(padding-bottom,0.2rem);
                        @include res(margin-bottom,0.15rem);
                        .iconfont{
                          transition: transform .4s;
                          @include res(margin-top,0.03rem);
                          @include res(margin-left,0.1rem);
                        }
                        &:hover{
                          color: $color-main;
                          .iconfont{
                            transform: translateX(0.05rem);
                          }
                        }
                      }
                      .nav-item-link{
                        a{
                          display: block;
                          color: #4c4c4c;
                          transition: color .4s;
                          @include res(font-size,0.2rem);
                          @include res(margin-top,0.12rem);
                          &:hover{
                            color: $color-main;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .right-fun{
          display: flex;
          align-items: center;
          @include res(margin-left,0.4rem);
          .touxiang{
            position: relative;
            @include res(display,null,(md:none));
            @include res(padding-left,0.21rem);
            @include res(margin-left,0.1rem);
            &::after{
              content: '';
              display: block;
              background-color: #dadffd;
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              transition: background-color .4s; 
              @include res(height,0.26rem);
              @include res(width,0.01rem,(md:1px));
            }
            img{
              display: block;
              border-radius: 50%;
              @include res(width,0.38rem,(md:0.48rem));
              @include res(height,0.38rem,(md:0.48rem));
            }
            .login{
              transition: all .3s;
              @include res(color,#fff,(md:#000));
              @include res(font-size, .18rem,(md:16px));
              &:hover{
                color: $color-main;
              }
            }
          }
          .nav-search{
            position: relative;
            transform: all .3s;
            &.active{
              form{
                opacity: 1;
                visibility: visible;
                box-shadow: 0px 0px 0.1rem rgba(0,0,0,.1);
                input{
                  @include res(width,2rem);
                }
              }
              .open-search{
                .iconfont{
                  &.icon-guanbi{
                    display: block;
                  }
                  &.icon-sousuo{
                    display: none;
                  }
                }
              }
            }
            form{
              position: absolute;
              visibility: hidden;
              transform-origin: right;
              transition: opacity .4s,visibility .4s,box-shadow .4s;
              opacity: 0;
              top: 0;
              right: 0;
              height: 100%;
              @include res(padding-left,0.5rem);
              button{
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                outline: none;
                background-color: transparent;
                border: none;
                z-index: 1;
                cursor: pointer;
                .iconfont{
                  @include res(font-size, 0.26rem,(md:0.36rem));
                }
              }
              input{
                height: 100%;
                outline: none;
                background-color: transparent;
                border: none;
                transition: width .4s;
                @include res(width,0px);
              }
            }
            .open-search{
              position: relative;
              right: 0.1rem;
              z-index: 1;
              cursor: pointer;
              line-height: 1.2;
              transition: color .4s; 
              @include res(top,0,(xs:2px));
              .iconfont{
                transition: color .4s;
                @include res(color,#fff,(md:#666666));
                @include res(font-size, 0.26rem,(md:0.46rem));
                &.icon-guanbi{
                  display: none;
                }
              }
            }
          }
          .hamburger{
            @include res(display,none,(md:block));
            @include res(margin-left, 0.3rem);
          }
        }
      }
    }
  }
  .mob-search{
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    position: fixed;
    bottom: 100%;
    left: 0;
    width: 100%;
    z-index: 1001;
    padding-left: .3rem;
    padding-right: .1rem;
    padding-top: .2rem;
    transition: transform .3s;
    @include res(height,1rem);
    @include res(display,none,(xs:flex));
    &.show-mob{
      transform: translateY(100%);
    }
    .close-search{
      flex-shrink: 0;
      width: .8rem;
      height: .9rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      .iconfont{
        font-size: .5rem;
      }
    }
    form{
      width: calc(100% - .9rem);
      height: .7rem;
      border: 1px solid #999999;
      display: flex;
      input{
        flex-grow: 1;
        height: .7rem;
        border: none;
        padding-left: .2rem;
        background-color: transparent;
      }
      button{
        width: .7rem;
        border: none;
        background-color: transparent;
        .iconfont{
          font-size: .4rem;
        }
      }
    }
  }
  .mob-header{
    position: fixed;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100vh;
    background-color: rgba(255,255,255,.8);
    backdrop-filter: blur(10px);
    z-index: 998;
    box-sizing: border-box;
    padding-top: 80px;
    padding-bottom: 80px;
    overflow-y: auto;
    overflow-x: hidden;
    transition: left .5s;
    &.active{
      left:0;
    }
    >ul{
      @include res(padding-left,5%);
      @include res(padding-right,5%);
      >li{
        border-bottom: 1px solid rgba(0,0,0,.1);
        &.active{
          .nav-title{
            .iconfont{
              transform: rotate(-90deg);
            }
          }
        }
        .nav-title{
          display: flex;
          align-items: center;
          justify-content: space-between;
          a{
            flex-grow: 1;
            font-weight: bold;
            font-size: 18px;
            line-height: 50px;
          }
          .iconfont{
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all .3s;
            transform-origin: center;
          }
        }
        .sub-title{
          display: none;
          // position: fixed;
          // top: 0;
          // left: 100%;
          width: 100%;
          // height: 100vh;
          // padding-bottom: 80px;
          // overflow-y: auto;
          // z-index: 999;
          // padding-top: 80px;
          // background-color: #fff;
          // transition: left .5s;
          @include res(padding-left,5%);
          // @include res(padding-right,5%);
          &.active{
            left:0;
          }
          .back-main-nav{
            display: none;
            margin-bottom: 15px;
            .iconfont{
              margin-right: 5px;
              font-size: 12px;
            }
          }
          .main-title{
            display: none;
            font-size: 20px;
            font-weight: bold;
            padding-bottom: 10px;
            border-bottom: 1px solid rgba(0,0,0,.1);
          }
          .slide-pro-nav{
            display: flex;
            align-items: stretch;
            justify-content: flex-start;
            flex-wrap: wrap;
            a{
              flex-shrink: 0;
              text-align: center;
              @include res(width, 20%,(xs:33.33%));
              @include res(margin-bottom,15px,(xs:10px));
              @include res(margin-top,15px,(xs:10px));
              .item-icon{
                @include res(margin-bottom,5px);
                img{
                  height: auto;
                  @include res(width,80%,(xs:70%));
                  @include res(max-width,100px);
                }
              }
              .item-t{
                @include res(font-size,16px,(xs:14px));
              }
            }
          }
          .slide-nav{
            li{
              &:not(:last-child){
                border-bottom: 1px solid rgba(0,0,0,.1);
              }
              &.active{
                .slide-title .iconfont{
                  transform: rotate(0deg);
                }
              }
              .slide-title{
                display: flex;
                align-items: center;
                justify-content: space-between;
                a{
                  display: inline-flex;
                  align-items: center;
                  flex-grow: 1;
                  line-height: 50px;
                  img{
                    width: 70px;
                    height: auto;
                  }
                }
                .iconfont{
                  width: 50px;
                  height: 50px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  transform-origin: center;
                  transform: rotate(90deg);
                  transition: all .3s;
                  flex-shrink: 0;
                  font-size: 14px;
                }
              }
              .slide-cont{
                display: none;
                padding-left: 35px;
                a{
                  display: block;
                  padding-bottom: 10px;
                  padding-top: 10px;
                  &:not(:last-child){
                    border-bottom: 1px solid rgba(0,0,0,.1);
                  }
                }
              }
            }
          }
        }
      }
      .icon-nav{
        display: flex;
        align-items: center;
        justify-content: center;
        @include res(margin-top,1rem);
        >li{
          &:not(:last-child){
            @include res(margin-right,50px,(sm:30px,xs:20px));
          }
          .nav-title{
            white-space: nowrap;
            text-align: center;
            .iconfont{
              width: 50px;
              height: 50px;
              background-color: #fafafa;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0 auto;
              font-size: 26px;
              color: #51505d;
            }
            span{
              color: #666;
              font-size: 15px;
              margin-top: 5px;
              display: block;
            }
            .mob-head{
              img{
                width: 50px;
                height: 50px;
                display: block;
                margin: 0 auto;
                border-radius: 50%;
              }
            }
          }
          .sub-title{
            position: fixed;
            top: 0;
            left: 100%;
            width: 100%;
            height: 100vh;
            padding-bottom: 80px;
            overflow-y: auto;
            z-index: 999;
            padding-top: 80px;
            background-color: #fff;
            transition: left .5s;
            @include res(padding-left,5%);
            @include res(padding-right,5%);
            &.active{
              left:0;
            }
            .back-main-nav{
              margin-bottom: 15px;
              .iconfont{
                margin-right: 5px;
                font-size: 12px;
              }
            }
            .main-title{
              font-size: 20px;
              font-weight: bold;
              padding-bottom: 10px;
              border-bottom: 1px solid rgba(0,0,0,.1);
            }
            .slide-pro-nav{
              display: flex;
              align-items: stretch;
              justify-content: flex-start;
              flex-wrap: wrap;
              a{
                flex-shrink: 0;
                text-align: center;
                @include res(width, 20%,(xs:33.33%));
                @include res(margin-bottom,15px,(xs:10px));
                @include res(margin-top,15px,(xs:10px));
                .item-icon{
                  @include res(margin-bottom,5px);
                  img{
                    height: auto;
                    @include res(width,80%,(xs:70%));
                    @include res(max-width,100px);
                  }
                }
                .item-t{
                  @include res(font-size,16px,(xs:14px));
                }
              }
            }
            .slide-nav{
              li{
                border-bottom: 1px solid rgba(0,0,0,.1);
                &.active{
                  .slide-title .iconfont{
                    transform: rotate(0deg);
                  }
                }
                .slide-title{
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  a{
                    display: inline-flex;
                    align-items: center;
                    flex-grow: 1;
                    line-height: 50px;
                    img{
                      width: 70px;
                      height: auto;
                    }
                  }
                  .iconfont{
                    width: 50px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transform-origin: center;
                    transform: rotate(180deg);
                    transition: all .3s;
                    flex-shrink: 0;
                    font-size: 14px;
                  }
                }
                .slide-cont{
                  display: none;
                  padding-left: 35px;
                  a{
                    display: block;
                    padding-bottom: 10px;
                    padding-top: 10px;
                    &:not(:last-child){
                      border-bottom: 1px solid rgba(0,0,0,.1);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .gp{
      text-align: center;
      margin-top: .8rem;
    }
  }
  .header-aside{
    .btn-icon{
      position: fixed;
      right: 0;
      z-index: 7;
      pointer-events: none;
      @include res(bottom,5%,(xs:8%));
      @include res(right,0.3rem);
      &.scroll{
        .message{
          transform: translateY(0%);
        }
        .back-top{
          opacity: 1;
          visibility: visible;
          transform: scale(1);
        }
      }
      .message,.back-top{
        border-radius: 50%;
        margin: 0 auto;
        position: relative;
        cursor: pointer;
        pointer-events: all;
        @include res(margin-bottom,0.2rem);
        @include res(width,0.9rem);
        @include res(height,0.9rem);
        // @media screen and(max-width:1366px) {
        //   @include res(width,0.7rem);
        //   @include res(height,0.7rem);
        // }
        .iconfont{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }
      .message{
        color: #cb4444;
        background-color: #fff;
        transform: translateY(100%);
        box-shadow: 0 0.05rem 0.15rem rgba(0,0,0,.1);
        transition: all .5s,color .5s;
        position: relative;
        z-index: 1;
        @include res(display,null,(xs:none));
        .iconfont{
          @include res(font-size, 0.4rem);
        }
        &:hover{
          background-color: $color-main;
          color: #fff;
        }
      }
      .back-top{
        color: #cb4444;
        background-color: #fff;
        transition: all .5s;
        box-shadow: 0px 0.05rem 0.15rem rgba(0,0,0,.1);
        opacity: 0;
        visibility: hidden;
        transform: scale(0);
        transform-origin: center;
        .iconfont{
          @include res(font-size, 0.26rem);
        }
        &:hover{
          transform: translateY(-0.05rem);
          box-shadow: 0 0.05rem 0.2rem rgba(0,0,0,.1);
          background-color: $color-main;
          color: #fff;
        }
      }
    }
    .aside-link{
      position: fixed;
      top: 0;
      left: 100%;
      z-index: 10001;
      background-color: #fff;
      box-shadow: 0 0 0.1rem rgba(0,0,0,.1);
      transition: transform .5s;
      @include res(width,36%,(sm:45%,xs:90%));
      &.show{
        transform: translateX(-100%);
      }
      .aside-link-close{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        @include res(width,0.6rem,(md:40px));
        @include res(height,0.6rem,(md:40px));
        &:hover{
          .iconfont{
            transform: rotate(90deg);
          }
        }
        .iconfont{
          display: inline-block;
          transition: transform .4s;
          @include res(font-size,0.22rem,(md:20px));
        }
      }
      .link1{
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        flex-wrap: wrap;
        @include res(padding-top,0.8rem);
        @include res(padding-left,0.45rem);
        @include res(padding-right,0.45rem);
        @include res(padding-bottom,0.3rem);
        a{
          display: block;
          border: solid #e5e5e5;
          text-align: center;
          transition: color .4s,background-color .4s,border .4s;
          color: #4c4c4c;
          @include res(border-width, 0.01rem,(md:1px));
          @include res(margin-bottom, 0.3rem);
          @include res(width,48%,(xs:49%));
          @include res(font-size,0.22rem,(md:16px));
          @include res(padding-top,0.35rem);
          @include res(padding-bottom,0.35rem);
          .iconfont{
            line-height: 1;
            @include res(font-size,0.34rem,(md:26px));
          }
          &:hover{
            background-color: $color-main;
            color: #fff;
            border: solid $color-main;
            @include res(border-width, 0.01rem,(md:1px));
          }
        }
      }
      .link2{
        background: url(./images/header_aside_bg.jpg) no-repeat center;
        background-size: cover;
        background-repeat: no-repeat;
        align-items: center;
        @include res(display,flex);
        a{
          display: block;
          position: relative;
          text-align: center;
          color: #fff;
          white-space: nowrap;
          @include res(font-size,0.2rem,(md:14px));
          @include res(width,50%);
          @include res(padding-top,0.6rem);
          @include res(padding-bottom,0.5rem);
          &:first-child{
            &::after{
              content: '';
              display: block;
              background-color: #fff;
              position: absolute;
              right: 0;
              @include res(top, 30%);
              @include res(height,40%);
              @include res(width,0.01rem,(md:1px));
            }
          }
          &:hover{
            .iconfont{
              transform: translateX(0.05rem);
            }
          }
          img{
            height: auto;
            @include res(width,.5rem,(md:50px));
          }
          span{
            // @include res(display,null,(md:none));
          }
          .iconfont{
            margin-left: 0.05rem;
            transition: transform .4s;
            display: inline-block;
            @include res(font-size, 0.14rem);
          }
        }
      }
      .link-tel{
        background-color: $color-main;
        color: #fff;
        text-align: center;
        line-height: 1.2;
        @include res(padding-top,0.3rem);
        @include res(padding-bottom,0.3rem);
        @include res(font-size, 0.2rem);
        .t2{
          font-family: 'din';
          @include res(font-size,0.46rem);
          .iconfont{
            @include res(margin-right,0.15rem);
            @include res(font-size,0.36rem);
          }
        }
      }
    }
  }
  .mob-aside{
    @include res(display,none,(xs:block));
    .aside-mask{
      z-index: 8;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,.6);
      position: fixed;
      top: 0;
      left: 0;
      display: none;
    }
    .mob-aside-title{
      position: relative;
      border-bottom: 1px solid #f2f2f2;
      text-align: center;
      padding: .2rem 0;
      font-size: .34rem;
      .iconfont{
        position: absolute;
        left: .2rem;
        top: 50%;
        transform: translateY(-50%);
        font-size: .4rem;
      }
    }
    .aside-btn{
      position: fixed;
      top: 100%;
      width: 100%;
      left: 0;
      z-index: 5;
      display: flex;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      overflow: hidden;
      box-shadow: 0 0 .2rem rgba(0,0,0,.1);
      transition: transform .3s;
      &.show-mob{
        transform: translateY(-100%);
      }
      a{
        width: 50%;
        height: 1rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        .iconfont{
          font-size: .4rem;
          margin-right: .1rem;
        }
        &:last-child{
          background-color: $color-main;
          color: #fff;
        }
      }
    }
    .mob-shop{
      position: fixed;
      top: 100%;
      left: 0;
      width: 100%;
      z-index: 9;
      background-color: #fff;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      overflow: hidden;
      transition: transform .3s;
      &.show-mob{
        transform: translateY(-100%);
      }
      .shop{
        padding: .3rem;
        a{
          display: flex;
          align-items: center;
          justify-content: flex-start;
          position: relative;
          background-color: #f5f6fb;
          padding: .2rem;
          border-radius: .2rem;
          &:not(:last-child){
            margin-bottom: .2rem;
          }
          img{
            display: block;
            width: .8rem;
            height: auto;
            margin-right: .2rem;
          }
          .iconfont{
            position: absolute;
            top: 50%;
            right: .2rem;
            transform: translateY(-50%);
          }
        }
      }
    }
    .mob-contact{
      position: fixed;
      top: 100%;
      left: 0;
      width: 100%;
      z-index: 9;
      background-color: #fff;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      overflow: hidden;
      transition: transform .3s;
      &.show-mob{
        transform: translateY(-100%);
      }
      .contact{
        padding: .3rem;
        .link1{
          display: flex;
          align-items: stretch;
          justify-content: space-between;
          a{
            .iconfont{
              font-size: .6rem;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 1.2rem;
              height: 1.2rem;
              border-radius: .15rem;
              background-color: #f5f6fb;
              color: $color-main;
              margin-bottom: .1rem;
            }
            p{
              text-align: center;
              font-size: .28rem;
            }
          }
        }
        .link2{
          margin-top: .4rem;
          a{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            background-color: #f5f6fb;
            border-radius: .15rem;
            padding: .2rem .3rem;
            .iconfont{
              color: $color-main;
              font-size: .44rem;
              margin-right: .2rem;
            }
            p{
              color: #444444;
              font-size: .46rem;
              font-family: 'din';
            }
          }
        }
      }
    }
  }
  .mask{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.4);
    position: fixed;
    top:0;
    left: 0;
    z-index: 996;
    display: none;
  }
}

//圆形进度条
@keyframes circle {
  0% {
    stroke-dashoffset: 55px;
  }
  50% {
    stroke-dashoffset: 28px;
  }
  100% {
    stroke-dashoffset: 0px;
  }
}